import { Autoplay, Swiper } from 'swiper/js/swiper.esm'
import { merge } from 'lodash'
Swiper.use([Autoplay])

export default {
  props: {
    cloneSlides: {
      type: Number,
      default: 5,
    },
    text: {
      type: String,
      default() {
        return this.$store.state.globals.infiniteText
      },
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    /*
    * Warning! Use custom prop cloneSlides instead of swiper loopedSlides option. It makes better loop animation
    * Warning! Do not use waitForTransition: false option! It makes a problem with loop animation.
    */
    swiperOptions() {
      const defaultOptions = {
        allowTouchMove: false,
        slidesPerView: 'auto',
        simulateTouch: false,
        loop: true,
        speed: 30000,
        spaceBetween: 16,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
      }
      return merge(defaultOptions, this.options)
    },
  },
}
