import { render, staticRenderFns } from "./InfiniteText.vue?vue&type=template&id=25d14c54&"
import script from "./InfiniteText.js?vue&type=script&lang=js&"
export * from "./InfiniteText.js?vue&type=script&lang=js&"
import style0 from "./InfiniteText.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports