<script>
import InstagramWidgetLazy from '~/themes/base/components/content/InstagramWidget/InstagramWidgetLazy.vue'

export default {
  extends: InstagramWidgetLazy,
  data() {
    return {
      componentFactory: () => import('./InstagramWidget.vue'),
    }
  },
}
</script>
