<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'InstagramWidgetLazy',
      componentFactory: () => import('./InstagramWidget.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.InstagramWidgetLazy > .PlaceholderBox {
  min-height: 50vh;

  @include media-breakpoint-up(lg) {
    min-height: 20vw;
  }
}
</style>
