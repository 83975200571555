import { hydrateWhenVisible } from 'vue-lazy-hydration'

import BusinessUserMixin from '@/mixins/BusinessUserMixin'
import InfiniteText from '@theme/components/utils/InfiniteText'
import InstagramWidget from '@theme/components/content/InstagramWidget/InstagramWidgetLazy'
import PageHomepage from '~/themes/base/components/shop/PageHomepage'

const HomepageLookbooks = () => import('@theme/components/content/HomepageLookbooks')
const HomepageOurStory = () => import('@theme/components/content/HomepageOurStory')

export default {
  extends: PageHomepage,
  mixins: [BusinessUserMixin],
  data() {
    return {
      isMounted: false,
      sliderSrcsetBreakpoints: [
        { viewportMinWidth: 1200, imageWidth: 1920 },
        { viewportMinWidth: 768, imageWidth: 1184 },
        { viewportMinWidth: 480, imageWidth: 768 },
        { viewportMinWidth: 320, imageWidth: 480 },
      ],
    }
  },
  components: {
    HomepageLookbooks: hydrateWhenVisible(HomepageLookbooks),
    HomepageOurStory: hydrateWhenVisible(HomepageOurStory),
    InfiniteText,
    InstagramWidget,
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    criticalContentBreakpoints() {
      this.sliderSrcsetBreakpoints
    },
    filteredFeaturedBanners() {
      if (!this.isMounted) return this.featuredBanners
      return this.featuredBanners.filter(item => {
        return item.customer_group_enabled.includes(this.isBusinessUser ? 'VO' : 'MO')
      })
    },
  },
}
