<script>
import Component from '~/sites/shop/pages'
export default {
  extends: Component,
  nuxtI18n: {
    paths: {
      cs: '/',
    },
  },
}
</script>
